<template>
  <div class="w-full">
    <div class="mb-10">
      <h2 class="text-2xl tracking-tighter leading-9 font-black text-center">
        Welcome back!
      </h2>
      <p class="text-sm mt-2 opacity-75 leading-5 text-center">
        Enter your email address to log into your account.
      </p>
    </div>

    <input
      v-model="email"
      aria-label="Email address"
      placeholder="Email address"
      name="email"
      type="email"
      required
      class="field appearance-none relative block text-base w-full px-3 py-2 border border-gray-200 dark:border-gray-800 bg-white/20 dark:bg-gray-900 rounded-lg placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:leading-5"
      @keydown.enter="handleKeyDown"
    >

    <div class="mt-3">
      <nuxt-link to="/auth/find/code" disabled class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        Continue
      </nuxt-link>
    </div>

    <div class="text-sm mt-6 opacity-75 text-center">
      <p>If you use the old version of Ovatu, please <a href="https://ovatu.com/location" class="underline">login here</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'auth',
  computed: {
    email: {
      get () {
        return this.$store.state.auth.auth.email
      },
      set (value) {
        this.$store.commit('auth/set', { key: 'email', value })
      }
    }
  },
  methods: {
    handleKeyDown () {
      if (this.email) {
        this.$router.push('/auth/find/code')
      }
    }
  }
}
</script>
